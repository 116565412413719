import * as React from "react"

const services = [
  { title: "Venue", icon: "fa-hotel" },
  { title: "Catering + Bar Service", icon: "fa-utensils" },
  { title: "Speakers + Entertainment", icon: "fa-glass-cheers" },
  { title: "Lodging + Transportation", icon: "fa-bed" },
  { title: "Audio Visual", icon: "fa-photo-video" },
  { title: "Design + Decor", icon: "fa-palette" },
  { title: "Rentals", icon: "fa-box-open" },
  { title: "Promotional Item(s)", icon: "fa-search-dollar" },
  { title: "Photography + Videography", icon: "fa-camera-retro" },
  { title: "Onsite Management", icon: "fa-id-badge" },
]

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

const splitServices = sliceIntoChunks(services, 2)

const ServiceItem = ({ icon, title }) => (
  <div className="py-6 is-hoverable is-clickable is-bordered service-item">
    <div>
      <span className="icon">
        <i className={`fa ${icon} fa-2x`} />
      </span>
    </div>
    <div className="mt-2 is-clearfix">
      <span className="is-size-5">{title}</span>
    </div>
  </div>
)

function ServicesPanel() {
  return (
    <section
      id="services"
      className="section is-medium has-background-primary is-bold has-text-centered has-text-primary-light"
    >
      <div className="container">
        <h2 className="subtitle is-size-3 is-size-2-tablet has-text-primary-light is-family-secondary">
          MADE Event Planning Services
        </h2>
        <div className="columns is-vcentered">
          {splitServices.map((services, i) => {
            console.log("SERVICES length: " + services.length)
            return (
              <div className="column">
                {services.map((service, j) => {
                  console.log("I: " + i + " J: " + j)
                  if (j % 2 === 0) {
                    // console.log(j + " is EVEN")
                  }
                  return (
                    <ServiceItem icon={service.icon} title={service.title} />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ServicesPanel
