import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoGrid from "../components/index/photo-grid"
import MainHero from "../components/index/main-hero"
import ServicesPanel from "../components/index/services-section"
import About from "../components/index/about"
import Testimonials from "../components/index/testimonials"
import Logos from "../components/index/logos"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MainHero />
    <About />
    <PhotoGrid />
    <ServicesPanel />
    <Testimonials />
    <Logos />
  </Layout>
)

export default IndexPage
