import * as React from "react"

import logo1 from "../../images/index/logo1.png"
import logo2 from "../../images/index/logo2.png"
import logo3 from "../../images/index/logo3.png"
import logo4 from "../../images/index/logo4.png"

const LogoItem = ({ img }) => (
  <div
    className="image is-256x256 m-5 is-vcentered logo-item"
    style={{
      backgroundImage: `url(${img})`,
    }}
  />
)

const Logos = () => (
  <section className="section has-background-primary-light is-bold">
    <div className="container">
      <h2 className="subtitle is-size-3 is-size-2-tablet has-text-primary has-text-centered">
        Satisfied Clients
      </h2>
      <div className="is-flex is-justify-content-center">
        <LogoItem img={logo1} />
        <LogoItem img={logo2} />
        <LogoItem img={logo3} />
        <LogoItem img={logo4} />
      </div>
    </div>
  </section>
)

export default Logos
