import * as React from "react"
import farmHerImage from "../../images/index/farmher.jpg"
import farmToTable from "../../images/index/farm-to-table.jpg"
import downtownImage from "../../images/index/index-hero.jpg"
import eventsCenterImage from "../../images/index/celebrations.jpg"

function PhotoGrid() {
  const PhotoGridItem = ({ image, text }) => (
    <div
      className={"photo-grid-item-wrapper is-clickable"}
      style={{
        height: `400px`,
        width: `100%`,
        overflow: `hidden`,
      }}
    >
      <div
        className={"photo-grid-item"}
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: `center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `cover`,
          height: `100%`,
          width: `100%`,
          display: `flex`,
          alignItems: `center`,
          alignContent: `center`,
          padding: `0 auto`,
          wordBreak: `break-all`,
        }}
      >
        <h1
          className={`subtitle has-text-light is-3 has-text-weight-medium has-text-centered is-family-secondary`}
          style={{ margin: `0 auto` }}
        >
          {text}
        </h1>
      </div>
    </div>
  )
  return (
    <>
      <div
        id="photo-grid"
        style={{ display: `grid`, gridTemplateColumns: `repeat(2, 1fr)` }}
      >
        <PhotoGridItem image={farmToTable} text={`FARM-TO-TABLE`} />
        <PhotoGridItem image={eventsCenterImage} text={`CELEBRATIONS`} />
        <PhotoGridItem image={farmHerImage} text={`CONFERENCES + MEETINGS`} />
        <PhotoGridItem image={downtownImage} text={`FUNDRAISERS`} />
      </div>
    </>
  )
}

export default PhotoGrid
