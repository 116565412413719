import * as React from "react"

const About = () => (
  <section id="about" className="section is-small">
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column is-one-third">
          <figure className="image is-2by3 is-bordered has-text-primary-light">
            <img src="https://bulma.io/images/placeholders/480x640.png" />
          </figure>
        </div>
        <div className="column">
          <h1
            className="title is-family-code py-2"
            style={{ transform: "rotate(-10deg)", transformOrigin: "top left" }}
          >
            hey there!
          </h1>
          <h1 className="title has-text-info has-text-weight-normal is-3 is-1-tablet">
            I'm a corporate event planner who loves creating custom events MADE
            just for you!
          </h1>
          <h2 className="subtitle has-text-info is-5 is-3-tablet">
            Meetings, Conferences, Farm-to-Tables, Fundraisers, Celebrations +
            more...
          </h2>
          <div className="content">
            <p>
              Watch as your outrageous generosity impacts the people +
              communities around you by creating JOY + Gratitude
            </p>
            <p>
              Let's change lives + give back through an unforgettable MADE
              event!
            </p>
            <h1 className="title is-family-code is-1">-Jen</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default About
