import hero from "../../images/index/index-hero.jpg"
import logo from "../../images/main-logo.png"
import * as React from "react"

const MainHero = () => (
  <section
    className="hero is-medium has-background"
    style={{
      backgroundImage: `url(${hero})`,
      backgroundAttachment: `fixed`,
      backgroundPosition: `center top`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `cover`,
      paddingTop: `45px`,
    }}
  >
    <div
      className="hero-body has-text-centered"
      style={{ background: `rgba(255,255,255,0.35)` }}
    >
      <div>
        <img src={logo} alt="made for this events logo" />
      </div>
    </div>
  </section>
)

export default MainHero
