import * as React from "react"

const Card = ({ name, text, title }) => (
  <div className="is-bold testimonial-card">
    <div className="testimonial-card-header mb-2">
      <div>
        <figure className="is-flex is-justify-content-center">
          <img
            className="testimonial-card-avatar"
            src="https://bulma.io/images/placeholders/128x128.png"
            alt=""
          />
        </figure>
      </div>
      <div className="content mt-2 has-text-centered testimonial-header">
        <h2 className="is-family-secondary">{name}</h2>
        <p>{title}</p>
      </div>
    </div>
    <div className="content">
      <p>{text}</p>
    </div>
  </div>
)

const Testimonials = () => (
  <section className="section">
    <div className="container">
      <h2 className="subtitle is-size-3 is-size-2-tablet has-text-primary has-text-centered is-family-secondary">
        What people are saying
      </h2>
      <div className="columns pt-4">
        <div className="column is-one-third p-4">
          <Card
            name="Lindsay Palmer"
            title="Producer for Mitch Matthews & the BIG Dream Gathering™"
            text={`"Working with Jen was an absolute joy from beginning to end. Her passion for events is evident and shows throughout 
            her attention to every detail throughout the process. We had to pivot throughout the planning process and she was the first 
            one to suggest new ideas, solutions, and workarounds. We’ve done countless events across the country and Jen helped to make 
            the planning as smooth and enjoyable as possible. If you have an event that you want to be a success, I’d highly recommend 
            partnering with Jen and Made for This Events."`}
          />
        </div>
        <div className="column is-one-third p-4">
          <Card
            text={`"In the live event space, it can be hard to find planners who are extremely detail-oriented AND relationally savvy. 
            We were pleasantly surprised to find BOTH traits in Jen and the Made for This team. Jen and her team set clear expectations, 
            guided feedback, all with a genuine desire to see us succeed. As a vendor, we couldn't have asked for a better partnership!"`}
            name="Ryan Gray"
            title="Emcee + Entertainer for the BIG Game Show"
          />
        </div>
        <div className="column is-one-third p-4">
          <Card
            name="Alissa Johnson"
            title="Executive Director for Bricker-Price Block"
            text={`"In the live event space, it can be hard to find planners who are extremely detail-oriented AND relationally savvy. 
            We were pleasantly surprised to find BOTH traits in Jen and the Made for This team. Jen and her team set clear expectations, 
            guided feedback, all with a genuine desire to see us succeed. As a vendor, we couldn't have asked for a better partnership!"`}
          />
        </div>
      </div>
    </div>
  </section>
)

export default Testimonials
